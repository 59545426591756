<template>
    <v-layout>
        <v-list-item @click="$router.push('/app/orders/view/' + order._id)" three-line :class="`${pStatusCss}`"
            class="bottom-border">
            <v-list-item-content>
                <v-layout>
                    <v-flex xs3>
                        <v-list-item-avatar :style="theme" size="50" tile>
                            {{ date }}
                        </v-list-item-avatar>
                    </v-flex>
                    <v-flex xs8>
                        <v-list-item-title class="align-card-title">
                            <v-icon class="grey--text" v-if="order?.delivery?.type?.code == 'home-delivery'"
                                small>mdi-truck-delivery</v-icon>
                            <v-icon class="grey--text" v-else small>mdi-storefront</v-icon><label class="ml-1">{{
            order.orderNo }}</label>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="order.customer">{{ order.customer.displayName
                            }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="card-subtitle-font">Rs {{ order.total }}</v-list-item-subtitle>
                    </v-flex>
                    <v-flex xs3>
                        <v-list-item-title class="align-card-chip">
                            <order-status :status="order.currentStatus"></order-status>
                        </v-list-item-title>
                        <v-list-item-subtitle class="card-subtitle-font">Order Date:
                            {{ order.date | dateFormat }}</v-list-item-subtitle>
                    </v-flex>
                </v-layout>
            </v-list-item-content>
            <v-list-item-action>
                <v-menu left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" v-if="order.pStatus != 'PAID'">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="$store.getters.vendor?.paymentDetails?.upiId && order.pStatus != 'PAID' && order.currentStatus == 'PENDING'">
                            <send-payment-details x-small :order="order"></send-payment-details>
                        </v-list-item>
                        <v-list-item v-if="isAllowed('editOrder') && orderActiveStates.includes(order.currentStatus)">
                            <action-button key="Edit" icon="mdi-pencil"
                                @click="$router.push('/app/orders/' + order._id)"></action-button>
                        </v-list-item>
                        <v-list-item v-if="$store.getters.vendor?.paymentDetails?.upiId && order.pStatus != 'PAID' && order.currentStatus == 'DELIVERED'">
                            <SendPaymentReminder :order="order" x-small></SendPaymentReminder>
                        </v-list-item>
                        <v-list-item v-if="isAllowed('deleteOrder')">
                            <action-button key="Delete" icon="mdi-delete"
                                @click="$emit('delete', order._id)"></action-button>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item-action>
        </v-list-item>
    </v-layout>
</template>

<script>
import moment from 'moment'
import SendPaymentReminder from './SendPaymentReminder.vue'
import appConstants from '@/utils/appConstants';
import SendPaymentDetails from './SendPaymentDetails.vue';
export default {
    components: {
        SendPaymentReminder,
        SendPaymentDetails,
    },
    props: {
        icons: {
            type: Array,
            required: true
        },
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            date: '',
            year: '',
            pStatusCss: 'CREDIT',
            orderActiveStates: appConstants.ORDER_ACTIVE_STATES,
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        initComponent() {
            this.date = moment(this.order.delivery.date, "DD/MM/YYYY").format('DD-MMM-YY')
            this.year = moment(this.order.date).format('YYYY')
            if (['CREDIT', 'PAID', 'PARTIAL'].indexOf(this.order.pStatus) > -1)
                this.pStatusCss = this.order.pStatus
        },
        completeAction(icon, id) {
            if (icon.name == "mdi-delete")
                this.$emit('delete', id)
            else
                this.performAction(icon, id)
        }
    },
};
</script>

<style scoped>
.align-card-chip {
    margin-top: 12px;
}

.align-avatar {
    margin-left: 15px;
    margin-top: 7px;
}

.align-card-title {
    margin-top: 12px;
}

.PAID {
    border-left: 5px solid green;
}

.PARTIAL {
    border-left: 5px solid orange;
}

.CREDIT {
    border-left: 5px solid red;
    margin: 1px 0px;
}

.bottom-border {
    border-bottom: 1px solid gray;
}
</style>
